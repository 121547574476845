import React, { useState }  from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default () => {

  const [formState, setFormState] = useState({
     email: ''
   })

  const [message, setMessage] = useState('')

  const { register, handleSubmit, errors } = useForm()

  const onChange = (e) => {
    setFormState({...formState, [e.target.name]: e.target.value })
  }

  const submitForm = async () => {

  try {
    const response = await fetch('/.netlify/functions/sendEmail', {
      method: 'POST',
      body: JSON.stringify(formState),
    })

    if (!response.ok) {
      console.log('not so gud 👎 ' + errors)
      return
    }

    setMessage('Thank you for keeping in touch 👍')
    console.log('gud 👍')

  } catch(e){
  }
}

  const {t} = useTranslation()
  return (
    <React.Fragment>
    { message? (
      <div className="relative bg-teal-150 border border-l-0 border-r-0 border-teal-500">
        <p className="py-5 text-teal-500">{message}</p>
      </div>
    ) : (
      <form method="post" onSubmit={handleSubmit(submitForm)} className="w-full max-w-sm" name="register" data-netlify="true">
       <input type="hidden" name="form-name" value="register" />
        <div className="flex items-center border-b border-blue-700 py-2">
          <input onChange={onChange} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder={t('Enter your email')} aria-label="email" name="email" ref={register({required: true, pattern: /^\S+@\S+$/i})} />
          <button type="submit" className="flex-shrink-0 bg-blue-700 hover:bg-blue-500 border-blue-700 hover:border-blue-500 text-sm border-4 text-white py-1 px-2 rounded">
            {t('Get Notified!')}
          </button>
        </div>
      </form>
    )}
    </React.Fragment>

  )
}
