    import React, { useState } from 'react'
    import { useTranslation } from 'gatsby-plugin-react-i18next'
    import Slider from 'react-slick'
    import 'slick-carousel/slick/slick.css'
    import 'slick-carousel/slick/slick-theme.css'

    export default (props) => {

      const {t} = useTranslation()

      const [currentClass, setCurrentClass ] = useState('')

      const settings = {
        speed: 1000,
        autoplaySpeed:4500,
        touchThreshold: 100,
        dots: false,
        arrows: false,
        autoplay:true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true
      }

      const list = props.data.map((item, index) =>
          <div key={index}><h3 className={`text-xl sm:text-2xl font-semibold h-16`}>{item}</h3></div>
        )
      return (
        <Slider {...settings}>
          {list}
        </Slider>
      )
    }

    
