import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../components/js/layout'
import ContactForm from '../components/js/ContactForm'
import Sprite from '../components/js/Sprite'
import { useHover } from '../components/js/useHover'
import ReactModal from 'react-modal'
import {  BrowserView, MobileView } from 'react-device-detect'
import Youths from '../images/youths.inline.svg'
import Youths2 from '../images/youths2.inline.svg'
import Parents from '../images/parents.inline.svg'
import Professionals from '../images/professionals.inline.svg'
import KidsUseSlider from '../components/js/KidsUseSlider'

ReactModal.setAppElement('#___gatsby')

export default function Home() {

  const {t} = useTranslation()
  const [hoverSmile, isSmile] = useHover()
  const [showModalIntro, setShowModalIntro] = useState(false)
  const [showModalBenefits, setShowModalBenefits] = useState(false)
  const [showModalLesson, setShowModalLesson] = useState(false)
  const [showModalPreview1, setShowModalPreview1] = useState(false)
  const [showModalPreview2, setShowModalPreview2] = useState(false)
  const [showModalPreview3, setShowModalPreview3] = useState(false)

   const customStyles = {
     overlay: {
       backgroundColor: 'rgb(0 0 0 / 75%)'
     },
     content : {
       top                   : '50%',
       left                  : '50%',
       right                 : 'auto',
       bottom                : 'auto',
       marginRight           : '-50%',
       transform             : 'translate(-50%, -50%)',
       padding               : '5px 5px 0px 5px',
       backgroundColor       : 'transparent',
       borderColor           : 'transparent',
       width                 : '100%',
       maxWidth              : '640px'
     }
   }

  return <div>
  <Helmet>
    <meta charSet="utf-8" />
    <title>{t('Dexter is a fun, interactive memory program powered by AI.')}</title>
    <link rel="canonical" href="https://dextermemory.com/" />
    <meta name="description" content={t('Dexter is a fun, interactive memory program powered by AI. It was designed by memory coaching experts with a proven track record of helping tens of thousands of people train their memory, technical math skills, processing and reading speeds.')} />
  </Helmet>

  <Layout >
    <section id="who" className="m-0 bg-gradient-to-r from-teal-400 to-blue-500 overflow-x-hidden px-6 lg:px-0">
    	<div className="container mx-auto flex flex-col lg:flex-row items-center pt-0 pb-6 md:pt-0 md:pb-12">
        <div className="flex flex-col w-full lg:w-1/2 justify-center items-start pt-12 lg:pt-6 pb-6 px-0 md:px-6">
          <h1 className="text-3xl sm:text-4xl mb-4 font-semibold md:w-full lg:w-5/6 xl:w-4/6">Dexter {t('Memory Training Program')}</h1>
          <p className="leading-normal mb-4">{t('Dexter is a fun, interactive memory program powered by AI. It was designed by memory coaching experts with a proven track record of helping tens of thousands of people train their memory, technical math skills, processing and reading speeds.')}</p>
          <div className="flex w-full sm:w-auto justify-center mt-2 rounded-lg text-lg">
            <button onClick={() => setShowModalIntro(true)} ref={hoverSmile} className="flex text-center text-lg w-full sm:w-auto bg-blue-500 hover:bg-blue-400 text-white font-semibold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span className="pl-3 pr-3 w-full sm:w-auto">{t('Meet Dexter')}</span></button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 lg:py-6 text-center relative">
          <MobileView>
            <img src="/dexter-mobile.png" className={`sprite-touch-enabled`} alt=""/>
          </MobileView>
          <BrowserView>
            <Sprite
              hoverSmile={hoverSmile}
              isSmile={isSmile}
            />
          </BrowserView>
        </div>
      </div>
    </section>
    <div className="svg-wrapper bg-gradient-to-r from-teal-400 to-blue-500">
      <svg viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path fill="#FFFFFF" fillOpacity="1" d="M0,192L34.3,181.3C68.6,171,137,149,206,122.7C274.3,96,343,64,411,69.3C480,75,549,117,617,133.3C685.7,149,754,139,823,112C891.4,85,960,43,1029,53.3C1097.1,64,1166,128,1234,154.7C1302.9,181,1371,171,1406,165.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
    </div>
    <section id="what" className="pt-12 pb-12 px-6 md:px-0">
      <div className="container mx-auto flex flex-col md:flex-row items-center my-0">
        <div className="flex flex-col w-full lg:w-1/2 justify-center items-start px-0 pt-0 pb-8 md:px-6 md:py-6">
          <h1 className="text-3xl sm:text-4xl font-semibold mb-5">{t('People are taught what to learn, not how to learn')}</h1>
          <p className="mb-2">{t('Dexter is an innovative memory training program that increases the processing power of your brain.')}</p>
          <p className="mb-4">{t('The lessons are delivered to you in simple games with a personalized learing experience implemented with interactive AI. You can spend a few minutes, or a few hours a day with the learning modules.')}</p>
          <button onClick={() => setShowModalBenefits(true)} className="relative flex items-center w-full sm:w-auto text-center text-lg font-semibold mt-3 py-2 px-4 text-white bg-blue-500 hover:bg-blue-400 border-b-4 border-blue-700 hover:border-blue-500 rounded">
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <span className=" w-full sm:w-auto text-lg pl-3 pr-3">{t('Learn More')}</span>
          </button>
        </div>

        <div className="w-full lg:w-1/2 text-center">
          <div className="grid grid-cols-3 gap-4">
          <button className="appearance-none" onClick={() => setShowModalLesson(true)}>
            <div className="flex items-center justify-center">
              <svg className="w-16 h-16 absolute text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" fill="#333333" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#333333" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
              </svg>
              <img src="/marketing-05.gif" className="mb-0" alt="" />
            </div>
          </button>
            <img src="/02.png" className="mb-0" alt="" />
            <img src="/03.png" className="mb-0" alt="" />
            <img src="/04.png" className="mb-0" alt="" />
            <button className="appearance-none" onClick={() => setShowModalPreview1(true)}>
              <div className="flex items-center justify-center">
                <svg className="w-16 h-16 absolute text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" fill="#333333" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#333333" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
                </svg>
                <img src="/marketing-03.gif" className="mb-0" alt="" />
              </div>
            </button>
            <img src="/06.png" className="mb-0" alt="" />
            <img src="/07.png" className="mb-0" alt="" />
            <img src="/08.png" className="mb-0" alt="" />
            <button className="appearance-none" onClick={() => setShowModalPreview2(true)}>
              <div className="flex items-center justify-center">
                <svg className="w-16 h-16 absolute text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" fill="#333333" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#333333" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
                </svg>
                <img src="/marketing-01.gif" className="mb-0" alt="" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div className="svg-wrapper">
      <svg viewBox="0 0 1440 320" preserveAspectRatio="none" className="fill-current">
      <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%"   stopColor="#4fd1c5"/>
        <stop offset="100%" stopColor="#4299e1"/>
      </linearGradient>
    </defs>
      <path fill="url(#gradient)" fillOpacity="1" d="M0,192L34.3,181.3C68.6,171,137,149,206,122.7C274.3,96,343,64,411,69.3C480,75,549,117,617,133.3C685.7,149,754,139,823,112C891.4,85,960,43,1029,53.3C1097.1,64,1166,128,1234,154.7C1302.9,181,1371,171,1406,165.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
    </div>

    <section id="kids" className="relative overflow-hidden bg-gradient-to-r from-teal-400 to-blue-500">
      <div className="container mx-auto">
        <div className="flex flex-col w-full sm:w-full p-5">
          <div className="sm:flex sm:flex-row w-full items-center text-center sm:text-left">
            <Youths2 className="w-32 h-32 mx-auto mb-4" alt="" />
            <h1 className="w-full sm:w-5/6 text-3xl sm:text-4xl font-semibold sm:ml-3">
            {t('What Happens When Kids Use Dexter?')}
            <KidsUseSlider
              data={[
                t('They Have Higher Self Confidence'),
                t('They Embrace Challenges'),
                t('They Learn From Failures'),
                t('They Are More Adaptable to Change'),
                t('They Work Harder')
              ]}
             />
            </h1>
          </div>
        </div>
      </div>
    </section>

    <div className="svg-wrapper bg-gradient-to-r from-teal-400 to-blue-500">
      <svg viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path fill="#FFFFFF" fillOpacity="1" d="M0,192L34.3,181.3C68.6,171,137,149,206,122.7C274.3,96,343,64,411,69.3C480,75,549,117,617,133.3C685.7,149,754,139,823,112C891.4,85,960,43,1029,53.3C1097.1,64,1166,128,1234,154.7C1302.9,181,1371,171,1406,165.3L1440,160L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
    </div>

    <section id="uses" className="mt-5">
      <div className="container mx-auto mb-8 md:px-6">
        <h1 className="text-3xl sm:text-4xl font-semibold px-6 md:px-0 mb-5 pb-3">{t('Who Can Use Dexter?')}</h1>
        <div className="mx-auto flex flex-col md:flex-row items-stretch my-0 md:divide-x md:divide-gray-300">

          <div className="flex flex-col w-full lg:w-1/3 pl-5 pr-5 pb-5 px-3">
            <div>
              <Youths className="w-32 h-32 lg:mx-auto" alt="" />
              <h4 className="mt-2 mb-3 lg:text-center text-lg font-semibold text-sm">{t('For Young Children')}</h4>
              <ul className="list-dash mt-5 ml-5 sm:mx-auto sm:w-3/4 text-sm">
                <li className="mb-1">{t('Teach them to think more creatively')}</li>
                <li className="mb-1">{t('Memory techniques that will allow them to learn new words')}</li>
                <li className="mb-1">{t('Enhance communication skills')}</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-1/3 pl-5 pr-5 pb-5 px-3">
            <div>
              <Parents className="w-32 h-32 lg:mx-auto" alt="" />
              <h4 className="mt-2 mb-3 lg:text-center text-lg font-semibold text-sm">{t('For Parents')}</h4>
              <ul className="list-dash mt-5 ml-5 sm:mx-auto sm:w-3/4 text-sm">
                <li className="mb-1">{t('Improve communication with children')}</li>
                <li className="mb-1">{t('Keep track of your daily schedule')}</li>
                <li className="mb-1">{t('Boost your cognitive reserve')}</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-1/3 pl-5 pr-5 pb-5 px-3">
            <div>
              <Professionals className="w-32 h-32 lg:mx-auto" alt="" />
              <h4 className="mt-2 mb-3 lg:text-center text-lg font-semibold my-2 text-sm">{t('As a Professional')}</h4>
              <ul className="list-dash mt-5 ml-5 sm:mx-auto sm:w-3/4 text-sm">
                <li className="mb-1">{t('Remember names when meeting new people')}</li>
                <li className="mb-1">{t('Increase creativity and productivity')}</li>
                <li className="mb-1">{t('Process information faster and speed up response time')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="aifndtn" className="bg-gradient-to-r from-gray-100 via-gray-300">
      <div className="container fluid mx-auto flex flex-col md:flex-row items-center my-0">
        <div className="flex flex-col w-full md:w-3/5 lg:w-1/2 justify-center items-start px-8 py-12 md:pl-12 md:py-6">
          <div className="lg:pl-2">
            <img src="/AIF-logo.png" className="lg:mb-5 md:mb-3" alt=""/>
            <p className="mb-3">{t('The AI Foundation is creating the world’s first personal AI extension platform to unlock human potential through sustainable AI.')}</p>
            <p>{t('Our mission is to bring the power and protection of AI to everyone in the world so we all can participate fully in the future.')}</p>
          </div>
        </div>
        <div className="w-full md:w-2/5 lg:w-1/2 text-center">
          <img src="/youth-love-learning.jpg" className="w-full mb-0" alt=""/>
        </div>
      </div>
    </section>

    <section id="register" className="h-96 bg-gray-100 p-6 sm:p-3">
      <div className="container fluid mx-auto flex flex-col md:flex-row items-center my-0">
        <div className="flex flex-col w-full lg:w-1/2 justify-center items-start pt-6 pb-6 sm:pl-12">
        <h1 className="text-2xl mb-1">{t('Pre-Register')}</h1>
        <p className="mb-4">{t('For a first look at Dexter program.')}</p>
         <ContactForm></ContactForm>
        </div>
      </div>
    </section>



  </Layout>

  <ReactModal
    isOpen={showModalIntro}
    onRequestClose={() => setShowModalIntro(false)}
    style={customStyles}
    contentLabel="Meet Dexter"
    >
    <button className="absolute z-10 bg-gray-800" onClick={() => setShowModalIntro(false)}> <svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.6' fill='#ffffff' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg> </button>
    <div style={{'padding': '69.86% 0 0 0', 'position' : 'relative'}}><iframe title="Meet Dexter" src="https://player.vimeo.com/video/483838470?title=0&byline=0&portrait=0" style={{'position': 'absolute', 'top':'0','left': '0', 'width':'100%', 'height': '100%'}} frameBorder="0" allow="fullscreen" allowFullScreen></iframe></div>
  </ReactModal>

  <ReactModal
    isOpen={showModalLesson}
    onRequestClose={() => setShowModalLesson(false)}
    style={customStyles}
    contentLabel="Dexter Lesson Preview"
  >
    <button className="absolute z-10 bg-gray-800 btn-close-video" style={{top:'4px',left: '5px'}} onClick={() => setShowModalLesson(false)}> <svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.6' fill='#ffffff' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg></button>
    <div style={{'padding': '69.86% 0 0 0', 'position' : 'relative'}}><iframe title="Dexter Lesson" src={`${t('SRC Lesson')}`} style={{'position': 'absolute', 'top':'0','left': '0', 'width':'100%', 'height': '100%'}} frameBorder="0" allow="fullscreen" allowFullScreen></iframe></div>
  </ReactModal>

  <ReactModal
    isOpen={showModalBenefits}
    onRequestClose={() => setShowModalBenefits(false)}
    style={customStyles}
    contentLabel="Benefits of Dexter Memory Program"
  >
    <button className="absolute z-10 bg-gray-800 btn-close-video" style={{top:'48px',left: '5px'}} onClick={() => setShowModalBenefits(false)}> <svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.6' fill='#ffffff' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg></button>
    <div style={{'padding': '69.86% 0 0 0', 'position' : 'relative'}}><iframe title="Benefits Of Dexter Memory Program" src={`${t('SRC Marketing')}`} style={{'position': 'absolute', 'top':'0','left': '0', 'width':'100%', 'height': '100%'}} frameBorder="0" allow="fullscreen" allowFullScreen></iframe></div>
  </ReactModal>

  <ReactModal
    isOpen={showModalPreview1}
    onRequestClose={() => setShowModalPreview1(false)}
    style={customStyles}
    contentLabel="Dexter Preview - Youth"
  >
    <button className="absolute z-10 bg-gray-800 btn-close-video" style={{top:'48px',left: '5px'}} onClick={() => setShowModalPreview1(false)}> <svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.6' fill='#ffffff' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg></button>
    <div style={{'padding': '69.86% 0 0 0', 'position' : 'relative'}}><iframe title="Dexter Demo" src="https://player.vimeo.com/video/481941382?title=0&byline=0&playsinline=0&portrait=0" style={{'position': 'absolute', 'top':'0','left': '0', 'width':'100%', 'height': '100%'}} frameBorder="0" allow="fullscreen" allowFullScreen></iframe></div>
  </ReactModal>

  <ReactModal
    isOpen={showModalPreview2}
    onRequestClose={() => setShowModalPreview2(false)}
    style={customStyles}
    contentLabel="Dexter Preview - Preston"
  >
    <button className="absolute z-10 bg-gray-800 btn-close-video" style={{top:'48px',left: '5px'}} onClick={() => setShowModalPreview2(false)}> <svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.6' fill='#ffffff' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg></button>
    <div style={{'padding': '69.86% 0 0 0', 'position' : 'relative'}}><iframe title="Dexter Demo - Preston" src="https://player.vimeo.com/video/492339890?title=0&byline=0&playsinline=0&portrait=0" style={{'position': 'absolute', 'top':'0','left': '0', 'width':'100%', 'height': '100%'}} frameBorder="0" allow="fullscreen" allowFullScreen></iframe></div>
  </ReactModal>

  <ReactModal
    isOpen={showModalPreview3}
    onRequestClose={() => setShowModalPreview3(false)}
    style={customStyles}
    contentLabel="Dexter Preview - Kaylee"
  >
    <button className="absolute z-10 bg-gray-800 btn-close-video" style={{top:'48px',left: '5px'}} onClick={() => setShowModalPreview3(false)}> <svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.6' fill='#ffffff' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg></button>
    <div style={{'padding': '69.86% 0 0 0', 'position' : 'relative'}}><iframe title="Dexter Demo - Kaylee" src="https://player.vimeo.com/video/492337110?title=0&byline=0&playsinline=0&portrait=0" style={{'position': 'absolute', 'top':'0','left': '0', 'width':'100%', 'height': '100%'}} frameBorder="0" allow="fullscreen" allowFullScreen></iframe></div>
  </ReactModal>

  </div>
}
